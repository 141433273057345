import request from '@/utils/request'

// 手机验证码登录
export function login0(data) {
    return request({
        url: '/SSO/LoginPhone?phone=' + data.phone + '&code=' + data.code + '&email=' + data.email + '&way=' + data.way + '&loginState=' + data.loginState,
        method: 'post',
    })
}

// !账号密码登录
export function login1(data) {
    return request({
        url: '/SSO/Login?account=' + data.account + '&password=' + data.password + '&loginState=' + data.loginState,
        method: 'post',
    })
}

// !退出
export function logout() {
    return request({
        url: '/SSO/LoginOut',
        method: 'get'
    })
}

// !获取二维码

export function GetWMImage(params) {
    return request({
        url: '/SSO/GetWMImage?wmUrls=' + params,
        method: 'get',
    })
}

// !验证二维码是否可用
export function GetWMImageState(params) {
    return request({
        url: '/SSO/GetWMImageState?wmId=' + params,
        method: 'get',
    })
}

// !验证是否授权
export function ValidateIsAuthorization(params) {
    return request({
        url: '/SSO/ValidateIsAuthorization',
        method: 'get',
        params
    })
}

// ! 跳转微信授权页面
export function RedirectWX(params) {
    return request({
        url: '/SSO/RedirectWX',
        method: 'get',
        params
    })
}

// !用code获取  微信账户信息
export function GetWXToken(params) {
    return request({
        url: '/SSO/GetWXToken',
        method: 'get',
        params
    })
}

// ! 验证是否绑定微信账号
export function ValidateRegister(params) {
    return request({
        url: '/SSO/ValidateRegister',
        method: 'get',
        params
    })
}

// !绑定账户/api/SSO/BindPhone
export function BindPhone(params) {
    return request({
        url: '/SSO/BindPhone',
        method: 'get',
        params
    })
}