<template>
  <div class="LoginWXRedirect"></div>
</template>

<script>
import { GetWXToken } from "@/api/user.js";

export default {
  name: "LoginWXRedirect",
  data() {
    return {
      codes: '',
      wmIds: '',
      Token: {},
      loca: window.location.href,
    };
  },
  mounted() {
    const code = window.location.href.split("=")[1];
    this.codes = code.split("&")[0];
    this.wmIds = window.location.href.split("=")[3];
    this.GetWXTokens();
  },
  methods: {
    // !coded
    GetWXTokens() {
      const code = {
        "code": this.codes,
        "wmId": this.wmIds,
      };
      GetWXToken(code).then(res => {
        if(res.status == 1) {
          this.$toast.success({ "message":"验证成功！", duration: 0,});
          setTimeout(() => {
            WeixinJSBridge.call('closeWindow');
          }, 1000);
        }else {
          this.$toast.fail({ "message": res.message, duration: 0,});
        }
      })
    }
  },
};
</script>

<style>
</style>